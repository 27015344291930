import React, {createContext, useState, useEffect} from 'react';
import PlaceService from '../services/PlaceService';
import queryString from 'query-string';

const PlaceContext = createContext({});

export const PlaceProvider = ({ children }) => {

	const queryStringParams = queryString.parse(window.location.search);
	const [menusData, setMenusData] = useState([]);
	const [menuNames, setMenuNames] = useState([]);
	const [restInfo, setRestInfo] = useState([]);
	

	useEffect(() => {
		// Live Menu Restaurant meals
		PlaceService.getMealsbymenu()
		.then((response) => {

			if(response?.data?.success){
				let menus = [];
				let menuNames = [];
				menus = response.data.data.menus;
				menus.map((menu,i) => {
					console.log("**menus**")
					console.log(menus)
					menuNames.push(
						{
							name: menu.name,
							order: menu.order
						}
					)
				})
				// Sorting menus in array by order
				menuNames.sort((a,b) => {return a.order - b.order})
				menus.sort((a,b) => {return a.order - b.order})
				setMenusData(menus);
				setMenuNames(menuNames);
			} else {
				if(response?.data){
					console.error('Error fetching meals by Categories:');
					console.error(response.data.message);
				}else{
					console.error('Error fetching menus by Categories:');
					console.error(response);
				}
			}
		})
		.catch((error) => {
			console.error('Request failed. Error: ', error);
		})

		PlaceService.getPlaceInfo()
		.then((response) => {
			if(response.data.success){
				setRestInfo(response.data.data.place);
				console.log('response.data.data:')
				console.log(response.data.data)
			} else {
				console.error('Error fetching meals by Categories:');
				console.error(response.data.message);
			}
		})
		.catch((error) => {
			console.error('Request failed. Error: ', error);
		})
	}, [])
	


	return (
		<PlaceContext.Provider value={{queryStringParams, menuNames, menusData, restInfo}}>
			{children}
		</PlaceContext.Provider>
	)
}

export default PlaceContext;