import React, { useContext, useState, useEffect } from 'react';
import PlaceContext from '../contexts/PlaceContext';
import queryString from 'query-string';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/App.css';
import { GoBook, GoArrowUp, GoGift } from 'react-icons/go';
import { MdHome } from 'react-icons/md';
import { FaStore } from 'react-icons/fa';
import * as Scroll from 'react-scroll';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { Link, useLocation } from 'react-router-dom';

const menuImage = {
	width: '100%',
	height: 'auto',
}

function Footer(props) {

	const {menuNames, menusData, queryStringParams, restInfo} = useContext(PlaceContext);

	const [showMenus, setShowMenus] = useState(false)
	const [restID, setRestID] = useState(null)
	const [numberOfMenus, setNumberOfMenus] = useState(1)
	const location = useLocation();
	const [pageveiwAnalyticsParams, setPageveiwAnalyticsParams] = useState({
		'navLocation': 'footer', 
		'event': 'virtualPageview', 
		'pageURL' : window.location.href, 
		'pageTitle' : window.location.pathname.replace(/^\/+/g, ''),
		'placeName' : restInfo.name ? restInfo.name : 'unknown place'
	})

	useEffect(() =>{
		setPageveiwAnalyticsParams(pageveiwAnalyticsParams => ({...pageveiwAnalyticsParams, 'placeName' : restInfo.name ? restInfo.name : 'unknown place'}))
	},[restInfo])

	// update the dataLayer with the new pageview data when restInfo changes and the place name is not unknown
	useEffect(() =>{
		if(pageveiwAnalyticsParams.placeName !== 'unknown place'){
			window.dataLayer.push(pageveiwAnalyticsParams)
		}
	},[pageveiwAnalyticsParams])
	
	useEffect(() => {
		setRestID(queryStringParams.restID)
		}, [queryStringParams])
	
	useEffect(() => {
		setNumberOfMenus(menuNames.length)
		}, [menuNames])

	const setMenuIndexFn = (i) => {
		setShowMenus(false);
		props.setMenuIndex(i)
	}

	const navOnClick = (routeName, title) => {
		const navData = {...pageveiwAnalyticsParams, 'pageURL' : window.location.host + '/' + routeName + '?restID=' + restID, 'pageTitle' : title};
		window.dataLayer.push(navData); 
		window.location.assign(routeName + '?restID=' + restID); 
	}

	const scrollToTop = () => {
		scroll.scrollToTop();
		props.scrollToTop();
	}

	return (
		<div className='grumbles-footer'>
		{numberOfMenus > 1 && showMenus ?
			<div style={{display:'flex', flexDirection:'column', width:'fit-content', cursor:'pointer'}}>
				{menuNames.map((menuName, i) => {
					return([
						<div key={'menuName-'+i} style={{backgroundColor:'#DC493A', border:'1px solid #fff', color:'#fff', padding:5}} onClick={() => setMenuIndexFn(i)}>
							{menuName.name}
						</div>		
					])
				})}
			</div>
		:null}

			<div className='footer-nav' style={{textAlign:'center',backgroundColor:'#DC493A'}}>
				<div className='footer-nav-element' onClick={() => numberOfMenus > 1 ? setShowMenus(!showMenus) : window.location.assign('qrlivemenu?restID=' + restID)}>
					<span style={{display:'flex', flexDirection:'row', marginLeft:numberOfMenus > 1 ? 10 : 0}}>
						<GoBook className='footer-nav-icon' />
						{numberOfMenus > 1 &&
						<div style={{background:'#10B49F', color:'#fff', borderRadius:'50%', height:20, width:20, fontSize:'10px', alignItems:'center', justifyContent:'center', display:'flex', fontWeight:'bold', marginLeft:-5, border:'1px solid #fff'}}>{numberOfMenus > 1 && numberOfMenus}</div>
						}
					</span>
					<span className='footer-nav-text'>Menus</span>
				</div>	
				<div className='footer-nav-element' onClick={() => window.location.assign('qrlivemenurestaurant?restID=' + restID)}>
					<FaStore className='footer-nav-icon' />
					<span className='footer-nav-text'>Details</span>
				</div>
				<div className={restID === '5964536066605056' ? 'footer-nav-element' : 'footer-nav-display-none'} onClick={() => {navOnClick('join','Join Us!')}}>
					<GoGift className='footer-nav-icon' />
					<span className='footer-nav-text'>Join In</span>
				</div>
				{/* <Link className='footer-nav-element' to={'join?restID=' + restID} onClick={() => {window.dataLayer.push({ 'navLocation': 'footer', 'event': 'Pageview', 'pageURL' : 'join?restID=' + restID, 'pageTitle' : 'Join Test', 'testURL': window.location}); console.log(window.dataLayer);}}>
					<GoGift className='footer-nav-icon' />
					<span className='footer-nav-text'>Join In2</span>
				</Link> */}
				<div className={restID === '5964536066605056' ? 'footer-nav-to-top' : 'footer-nav-element' } onClick={() => scrollToTop()}>
					<GoArrowUp className={restID === '5964536066605056' ? 'footer-nav-icon-floating' : 'footer-nav-icon' } />
					<span className={restID === '5964536066605056' ? 'footer-nav-to-top-text' : 'footer-nav-text'}>To Top</span>
				</div>
			</div>
		</div>
	);
	
};

export default Footer;