// add react-device-detect to a utility function
import { osName, osVersion, mobileModel } from "react-device-detect";

// function to track model-viewer events in GA4 with window.dataLayer
// 
export function trackMenuItemClick(eventParams) {
	window.dataLayer.push({ ...eventParams, 'osName' : osName, 'osVersion' : osVersion, 'mobileModel' : mobileModel });
}

export function trackClicks(eventParams) {
	window.dataLayer.push({ ...eventParams, 'osName' : osName, 'osVersion' : osVersion, 'mobileModel' : mobileModel });
}
