import React, { Component } from 'react';
import ReactPixel from 'react-facebook-pixel';

export default class dl extends Component {

	componentDidMount(){

		// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

		const options = {
			autoConfig: true, // set pixel's autoConfig
			debug: false, // enable logs
		  };
		  ReactPixel.init('304023040727493', '', options);
		  
		  ReactPixel.pageView(); // For tracking page view
		  
		if( /iPhone/i.test(navigator.userAgent) ) {
			window.location.href = 'https://itunes.apple.com/us/app/grumbles-food/id1376916995?mt=8';
		}else if( /Android/i.test(navigator.userAgent) ){
			window.location.href = 'https://play.google.com/store/apps/details?id=com.grumblesapp';
		}
		if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
			window.location.href = 'https://play.google.com/store/apps/details?id=com.grumblesapp';
		}
		if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
			window.location.href = 'https://itunes.apple.com/us/app/grumbles-food/id1376916995?mt=8';
		}
		
	}

	render() {
		return (
			<div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', textAlign:'center', backgroundColor:'#DC493A', height:'100%', padding:"50px"}}>
				<p>
					<img src="https://www.grumbl.es/home/wp-content/uploads/2018/06/grumblesBearText150x150.png" alt="Grumbles Logo" width="150px"/>
				</p>
				<p style={{color:'#fff'}}>
				Grumbles is now live!
				</p>
				<p style={{color:'#fff'}}>Open this link, <span style={{textDecoration:'underline'}}>https://www.grumbl.es/dl.html</span> on your smartphone</p>
				<p style={{color:'#fff', fontSize:'1.5em'}}>OR</p>
				<p style={{color:'#fff'}}>point your smartphone's camera here</p>
				<p>
					<img src="https://www.grumbl.es/img/qr-grumbles-dl.png" alt="QR code to download Grumbles" width="200px"/>
				</p>
				<p style={{color:'#fff'}}>to download Grumbles & see the NEW What's Open Map</p>
				<p style={{color:'#fff'}}>to find all the restaruants around you that are open during quarantine!</p>
				<p>
					<a href="https://itunes.apple.com/us/app/grumbles-food/id1376916995?mt=8"><img src="http://www.grumbl.es/img/appledlbutton.png" alt="Grumbles iTunes" /></a>
					<a href="https://play.google.com/store/apps/details?id=com.grumblesapp"><img src="http://www.grumbl.es/img/googleplaydlbutton.png" alt="Grumbles Play Store" /></a>
				</p>
			</div>
		)
	}
}
