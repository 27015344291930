import React, { Component, useState } from 'react';
import PlaceService from '../services/PlaceService';
import queryString from 'query-string';
import {DropdownButton, Dropdown, Row, Col, Carousel, Modal, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/App.css';
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { MdPhone, MdPublic, MdPinDrop } from  'react-icons/md';
import { FaFacebook, FaTwitter, FaInstagram, FaFileExcel } from 'react-icons/fa'
import Footer from '../components/Footer';
import { trackClicks } from '../utils/tracking';

export default class QrLiveMenu extends Component {

	constructor(props) {
		super(props);
	 
		this.state = {
			formattedHours:[],
			infoDelivery:{},
			infoGiftCard:{},
			infoPickup:{},
			navigationAddress: '',
			pickupDeliveryMethods: [],
			pickUpInfo: [],
			positionCurrent: 0,
			restID: '',
			restInfo: {},
			restHaveImage: false,
			restDescription: '',
			restDescriptionMore: false,
			restOfferings: {},
		};
		// this.numPages = this.numPages.bind(this);
	  }
	 
	componentDidMount(){
		window.addEventListener('scroll', this.listenToScroll)

		const queryStringParams = queryString.parse(this.props.location.search);
		this.setState({
			restID: queryStringParams.restID
		});
		console.log(queryStringParams.restID);

		PlaceService.getPlaceInfo()
		.then(response => {
			console.log('response.data:');
			console.log(response.data);
			console.log('place:');
			console.log(response.data.data.place);
			var strippedCommas = response.data.data.place.address.replace(',', '');
			var navReadyAddress = strippedCommas.replace(' ', '+');

			var noRestImage = response.data.data.place.coverImage.includes("noPhotosPlaceholder.jpg");

			this.setState({
				restInfo:response.data.data.place,
				restDescription:response.data.data.place.description,
				restHaveImage:!noRestImage,
                infoDelivery:response.data.data.place.metadata.delivery,
                infoPickup:response.data.data.place.metadata.pickup,
				infoGiftCard:response.data.data.place.metadata.giftcard,
				formattedHours:response.data.data.place.openingHours.formatted_periods,
				navigationAddress:navReadyAddress,
			}, () => {
				let	deliveryMethods = [
					{
						name: 'NotAvailable',
						matches: [
							'not-available',
						],
					},
					{
						name: 'Call',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/2013-08-26_09-38-25__Phone_iOS7_App_Icon_Rounded.png',
						event: 'tel:' + this.state.restInfo.internationalPhoneNumber,
						matches: [
							'phone',
							'call',
						],
					},
					{
						name: 'Grubhub',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/1200x630waGH.png',
						event: 'https://www.grubhub.com/',
						matches: [
							'grubhub',
						],
					},
					{
						name: 'Doordash',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/1200x630waDD.png',
						event: 'https://www.doordash.com/',
						matches: [
							'doordash',
						],
					},
					{
						name: 'delivery.com',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/1200x630waDLVR.png',
						event: 'https://www.delivery.com/',
						matches: [
							'delivery.com',
						],
					},
					{
						name: 'Caviar',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/1200x630waCV.png',
						event: 'https://www.trycaviar.com/',
						matches: [
							'caviar',
						],
					},
					{
						name: 'Uber Eats',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/1200x630waUE.png',
						event: 'https://www.ubereats.com/',
						matches: [
							'ubereats',
						],
					},
					{
						name: 'Postmates',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/1200x630waPM.png',
						event: 'https://postmates.com/',
						matches: [
							'postmates',
						],
					},
					{
						name: 'Seamless',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/428x428sl.png',
						event: 'https://www.seamless.com/',
						matches: [
							'seamless',
						],
					},
					{
						name: 'Back & Mobile',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/180x180bm.png',
						event: 'https://www.blackandmobile.com/',
						matches: [
							'blackandmobile',
						],
					},
					{
						name: 'Ritual',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/428x428rt.png',
						event: 'https://www.ritual.co/',
						matches: [
							'ritual',
						],
					},
					{
						name: 'Instagram',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/428x428ig.png',
						event: this.state.restInfo.instagram ? this.state.restInfo.instagram : 'https://www.instagram.com/',
						matches: [
							'instagram',
						],
					},
					{
						name: 'Messenger',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/428x428fm.png',
						event: this.state.restInfo.facebook ? this.state.restInfo.facebook : 'https://www.facebook.com/',
						matches: [
							'messenger',
							'facebook',
						],
					},
					{
						name: 'Website',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/wwwicon.png',
						event: this.state.restInfo.website,
						matches: [
							'website',
							'restaurantWebsite',
						],
					},
					{
						name: 'Other',
						icon: 'https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/wwwicon.png',
						event: 'https://www.',
						matches: [
							'http',
							'www',
						],
					},
				];
				this.setState({pickupDeliveryMethods: deliveryMethods});

			});//End of Fetch

			var offerings = [];

			console.log('offerings:');
			console.log(offerings);
			this.setState({restOfferings:offerings}, () => {
				console.log('this.state.restOfferings:');                        
				console.log(this.state.restOfferings);                        
			});

	
	
		})
		.catch(function(error) {  
			console.log('places request failed. Error: ', error);
		});

	}

	handleDismiss = () => {
		this.setState({
			modelImagesShow:false
		});		
	}

	setMenuIndex = (menuIndex) => {
		window.location.assign('qrlivemenu?restID=' + this.state.restID + '&menuIndex=' + menuIndex) 
	}

	scrollToTop() {
		scroll.scrollToTop();
	}

	render() {
		return (
			<div className="restaurant-details-container">
				<div className='restaurant-details'>
					{this.state.restHaveImage ?
					<div className='restaurant-cover-image-container'>
						<img src={this.state.restInfo.coverImage} className='restaurant-cover-image'/>
					</div>
					:null}
					<div className='restaurant-header'>
						<div className='restaurant-name-social'>
							<h2 className='restaurant-name'>{this.state.restInfo.name}</h2>
							{this.state.restInfo.facebook || this.state.restInfo.instagram || this.state.restInfo.twitter ?
							<div className='restaurant-social'>
							{this.state.restInfo.facebook ?
								<div>
									<a target='_blank' href={this.state.restInfo.facebook}><FaFacebook /></a>
								</div>
							:null}
							{this.state.restInfo.instagram ?
								<div>
									<a target='_blank' href={this.state.restInfo.instagram}><FaInstagram /></a>
								</div>
							:null}
							{this.state.restInfo.twitter ?
								<div>
									<a target='_blank' href={this.state.restInfo.twitter}><FaTwitter /></a>
								</div>
							:null}
						</div>
						:null}
						</div>
						{this.state.restDescription.length > 50 && !this.state.restDescriptionMore ?
							<React.Fragment>
								<h5>{this.state.restDescription.substring(0,75).trim() + '...'}<span style={{color:'blue'}} onClick={() => this.setState({restDescriptionMore:true})}>more</span></h5>
							</React.Fragment>
						: this.state.restDescription.length > 50 && this.state.restDescriptionMore ?
							<React.Fragment>
								<h5>{this.state.restDescription + ' '}<span style={{color:'blue'}} onClick={() => this.setState({restDescriptionMore:false})}>less</span></h5>
							</React.Fragment>
						:
							<h5>{this.state.restDescription}</h5>
						}
						
					</div>
					<div className='restaurant-info'>
						<div>
							<MdPhone style={{marginRight:'5px'}}/>
							<a target='_blank' href={'tel:'+this.state.restInfo.formattedPhoneNumber}>{this.state.restInfo.formattedPhoneNumber}</a>
						</div>
						{this.state.restInfo.website ?
						<div>
							<MdPublic style={{marginRight:'5px'}}/>
							<a target='_blank' href='this.state.restInfo.website'>{this.state.restInfo.website}</a>
						</div>
						:null}
						{this.state.restInfo.address ?
						<React.Fragment>
						<div>
							<MdPinDrop style={{marginRight:'5px'}}/>
							{this.state.restInfo.address}
						</div>
						<div className='navigation-to'>
							<h5 className='navigation-to-header'>
								Navigate With
							</h5>
							<div className='navigation-icons'>
								<a target='_blank' href={'http://waze.to/?ll='+this.state.restInfo.coordinate.Lat+','+ this.state.restInfo.coordinate.Lng +'&navigate=yes'} onClick={()=> trackClicks({'event' : 'directionsClick', 'method': 'waze'})}><img height='50px' src='http://www.grumbl.es/img/Waze-Icon.png'/> Waze</a>
								<a target='_blank' href={'https://maps.google.com/?daddr=' + this.state.navigationAddress} onClick={()=> trackClicks({'event' : 'directionsClick', 'method': 'google-maps'})}><img height='50px' src='http://www.grumbl.es/img/googl-maps-icon.png'/> Google</a>
								<a target='_blank' href={'https://maps.apple.com/?daddr=' + this.state.navigationAddress} onClick={()=> trackClicks({'event' : 'directionsClick', 'method': 'apple-maps'})}><img height='50px' src='http://www.grumbl.es/img/apple-maps-icon.png'/> Apple</a>
							</div>
						</div>
						</React.Fragment>
						:null}
					</div>
					{this.state.infoDelivery.active || this.state.infoPickup.active || this.state.infoGiftCard.active ?
						<div className='services-section'>
							<div className='services-section-header'>
								Services
							</div>
							{this.state.infoDelivery.active ?
							<div className='method-section'>
							<h5 className='method-section-header'>Delivery</h5>
							{this.state.infoDelivery.description ?
							<h6>{this.state.infoDelivery.description}</h6>
							:null}
							<div className='method-icons-container'>
							{
								this.state.infoDelivery.waysTo.map((way, x) => {
									return([
										<React.Fragment>
										{/* <span>way: {way}</span> */}
									{this.state.pickupDeliveryMethods.map((method, i) => {
										return([
											<React.Fragment>
											{method.matches.indexOf(way.trim()) !== -1 ?
												<div key={i+"delivery"} >
													<a target='_blank' href={method.event} className='method-button' onClick={()=> trackClicks({'event' : 'deliveryClick', 'method': method.name})}>
														<img src={method.icon} height='50px'/>
														{method.name}
													</a>
												</div>
											: null}
											</React.Fragment>
										])	
									})}
									</React.Fragment>
									])	
								})
							}
							</div>
						</div>
							:null}
							
							{this.state.infoPickup.active ?
							<div className='method-section'>
							<h5 className='method-section-header'>Pickup</h5>
							{this.state.infoPickup.description ?
							<h6>{this.state.infoPickup.description}</h6>
							:null}
							<div className='method-icons-container'>
							{
								this.state.infoPickup.waysTo.map((way, x) => {
									return([
										<React.Fragment>
										{/* <span>way: {way}</span> */}
									{this.state.pickupDeliveryMethods.map((method, i) => {
										return([
											<React.Fragment>
											{method.matches.indexOf(way.trim()) !== -1 ?
												<div key={i="pickup"}>
													<a target='_blank' href={method.event} className='method-button' onClick={()=> trackClicks({'event' : 'pickupClick', 'method': method.name})}>
														<img src={method.icon} height='50px'/>
														{method.name}
													</a>
												</div>
											: null}
											</React.Fragment>
										])	
									})}
									</React.Fragment>
									])	
								})
							}
							</div>
						</div>
							:null}

							{this.state.infoGiftCard.active ?
							<div className='method-section'>
							<h5 className='method-section-header'>Giftcards</h5>
							{this.state.infoGiftCard.description ?
							<h6>{this.state.infoGiftCard.description}</h6>
							:null}
								<div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
									<a target='_blank' href={this.state.infoGiftCard.waysTo[0]} className='method-button' onClick={()=> trackClicks({'event' : 'giftCardClick'})}>
										<img src='https://storage.googleapis.com/grumbles-api.appspot.com/resources/logos/gift-card-icon-vector-260nw.png' height='50px'/>
									</a>
								</div>
							</div>
							:null}

						</div>
					:null}
						<div className='hours-section'>
							<div className='hours-section-header'>
								Hours
							</div>
							<div className='hours-formatted'>
							{this.state.formattedHours ?
								Object.keys(this.state.formattedHours).map((period, i) => {
									return([
										<span>{this.state.formattedHours[period]}</span>
									])	
								})
							:null}
							</div>
						</div>
						<div className='powered-by-footer'>
							<div className='powered-by-footer-live-menus'>
								<a target="_blank" href='https://www.seethespecials.com	'>See The Specials</a>&nbsp;
							</div>
							<div className='powered-by-footer-grumbles'>
								is powered by <a target="_blank" href="https://www.grumbl.es">Grumbles</a>
							</div>
						</div>
				</div>
				<Footer setMenuIndex={this.setMenuIndex} scrollToTop={this.scrollToTop} />
			</div>
		)
	}
}
