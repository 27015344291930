import React, { Component, useContext } from 'react';
import PlaceService from '../services/PlaceService';
import PlaceConext, { PlaceProvider } from '../contexts/PlaceContext';
import Footer from '../components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/App.css';
import {DropdownButton, Dropdown, Row, Col, Carousel, Modal, Button, Spinner } from 'react-bootstrap';
import { MdImportantDevices, MdScreenRotation } from  'react-icons/md';
import queryString from 'query-string';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import * as Scroll from 'react-scroll';
import { isMobile, osName, osVersion, mobileModel, isTablet } from 'react-device-detect';
import { trackMenuItemClick } from '../utils/tracking';

export default class QrLiveMenu extends Component {

	constructor(props) {
		super(props);
	 
		this.state = {
			activeSection: '',
			allActiveMenuInfo: [],
			displayedMenuIndex: 0,
			getMealsbymenu: {},
			hasTitles: false,
			headerHeight: 0,
			isAR: false,
			isPhoto: false,
			isURL: true,
			mealsData: [],
			menusData: [],
			menuImages: [],
			menuInfoToDisplay: [],
			menuNames: [],
			modelImages: [],
			modelImagesShow: false,
			numPages: null,
			positionCurrent: 0,
			positionsSections: {},
			restID: '',
			restInfo: {},
			showSplash: true,
			showLiveMenu: false,
			specialsMenus: [],
			uniqueMenuTitles: [],
			osNameNVersion: osName + ' ' + osVersion,
			mobileModel: mobileModel,
			screenWidth: window.innerWidth,
			isWidthMobile: window.innerWidth <= 768,
		};
		// this.sectionDivs = useRef([]);
		this.modelRef = React.createRef();
	  }
	 

	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	}

	componentDidMount(){
		const queryStringParams = queryString.parse(this.props.location.search);
		// TODO: Temp solution till we convert menu and restaurant screens to functional components. 
		// This takes menuIndex passed from the restaurant screen footer when user clicks on a menu
		if(queryStringParams.menuIndex){
			this.setMenuIndex(queryStringParams.menuIndex);
		}

		window.addEventListener('scroll', this.listenToScroll)
		window.addEventListener('resize', this.handleWindowSizeChange);
		console.log("isWidthMobile:", this.state.isWidthMobile);
		
		this.splashScreen();

		this.setState({
			restID: queryStringParams.restID,
			isAR: queryStringParams.ar,
		});
	

		// Live Menu Restaurant meals
		PlaceService.getMealsbymenu()
		.then((response) => {
			if(response.data.success){
				let menus = [];
				let menuNames = [];
				menus = response.data.data.menus;
				menus.map((menu,i) => {
					menuNames.push(
						{
							name: menu.name,
							order: menu.order
						}
					)
				})
				// Sorting menus in array by order
				menuNames.sort((a,b) => {return a.order - b.order})
				menus.sort((a,b) => {return a.order - b.order})
				this.setState({
					menusData:menus,
					menuNames: menuNames
				}, () => {
					// scroll to section if hash is present in url after 3 seconds (to allow page to load) 
					setTimeout(()=>{
						if(window.location.hash){
							scroller.scrollTo(window.location.hash.substring(1), {
							duration: 800,
							delay: 0,
							smooth: 'easeInOutQuart',
							offset: -this.state.headerHeight
						  })			
						}
					}, 3000);
				});

				// this.sectionDivs = new Array(menus.sections.length);

				// this.getSectionPositions();

			} else {
				console.error('Error fetching meals by Categories:');
				console.error(response.data.message);
			}
		})
		.catch((error) => {
			console.error('Request getMealsbymenu failed. Error: ', error);
		})
		// Live Menu Restaurant Info for logo and name
		PlaceService.getPlaceInfo()
		.then(response => {
			this.setState({
				restInfo:response.data.data.place,
			});//End of Fetch
		})
		.catch((error) => {
			console.error('Request getPlaceInfo failed. Error: ', error);
		})


	}

	componentWillUnmount(){
		window.removeEventListener('scroll', this.listenToScroll);
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		this.setState({screenWidth:window.innerWidth,isWidthMobile:window.innerWidth <= 768});
		console.log("window.innerWidth:");
		console.log(window.innerWidth);
		console.log("isWidthMobile:", this.state.isWidthMobile);

	}


	getSectionPositions = () => {
		var positionsSections = {};
		this.state.menusData.map((menu, i) => {
			// TODO: loop through all the sections
			// add their ids and postions to positionsSections
			// menu.sections.map((section, i) => {
			// 	let saniClassName = section.name.replace(/\s/g, '-').toLowerCase()
			// 	console.log('saniClassName:')
			// 	console.log(saniClassName)
			// 	let sectionClass = document.getElementsByClassName('section-'+saniClassName);
			// 	console.log('sectionClass:')
			// 	console.log(sectionClass)

			// 	let rect = window.scrollY + document.getElementsByClassName('section-'+saniClassName).getBoundingClientRect().top
			// 	// let rect = sectionClass.getBoundingClientRect();
			// 	console.log('rect:');
			// 	console.log(rect);
			// 	positionsSections = {
			// 		sectionName: sectionClass,
			// 		sectionPosition: rect
			// 	}
			// 	console.log('positionsSections:');
			// 	console.log(positionsSections);
			// })
		})
		// loop through var positionsSections and find endRange by the next element's position
		// object state.positionsSections with start postion, endRange, element id
		// https://stackoverflow.com/questions/442404/retrieve-the-position-x-y-of-an-html-element-relative-to-the-browser-window
	};

	handleDismiss = () => {
		this.setState({
			modelImagesShow:false
		});		
	}

	listenToScroll = () => {
		const winScroll =
		  document.body.scrollTop || document.documentElement.scrollTop
	  
		const height =
		  document.documentElement.scrollHeight -
		  document.documentElement.clientHeight
	  
		const scrolled = winScroll / height
	  

		// TODO: loop through all the sections positions (state.positionsSections)
		// if positionCurrent >= positionSection && positionCurrent < positionSectionEndRange
		// then setState activeSection to positionSection name (ie second-course)

		this.setState({
			positionCurrent: scrolled,
		})
	  }
	
	recalcHeaderHeight = () => {
		if(document.getElementById('menu_nav_header')){
			let headerHeight = document.getElementById('menu_nav_header').clientHeight;	
			this.setState({
				headerHeight
			});
		}
	}

	// toggleMenus (title){
	// 	console.log('triggered');
	// 	let chosenMenu = [];
	// 	if(title === "allmenus"){
	// 		this.setState({
	// 			menuInfoToDisplay:this.state.allActiveMenuInfo
	// 		});		
	// 	}else{
	// 		this.state.allActiveMenuInfo.map((menuInfo, i) => {
	// 			if(menuInfo.menuMetadata.title === title){
	// 				chosenMenu.push(menuInfo);
	// 			}
	// 		})
	// 		console.log('chosenMenu: ');
	// 		console.log(chosenMenu);
	// 		this.setState({
	// 			menuInfoToDisplay:chosenMenu
	// 		});		
	// 	}
	// }

	scrollToTop() {
		scroll.scrollToTop();
	}

	showMealImage = (mealID) => {
		this.state.menusData.map((menu,i) => {
			menu.sections.map((section, i) => {
				section.meals.map((meal, i) => {
					if(meal.ID === mealID){
						this.setState({
							modelImages:meal.photos,
							modelImagesShow: !this.state.modelImagesShow,
						});
					}
				// pass images to model courosel
				})
			})
		})
	}

	sectionNavClick = (sectionButton) => {
		this.setState({activeSection:sectionButton});
		let offSet = this.state.headerHeight;
		scroller.scrollTo(sectionButton, {
			duration: 800,
			delay: 0,
			smooth: 'easeInOutQuart',
			offset: -offSet
		  })
		// add hash to url for sharing and push to dataLayer for GTM
		  window.location.hash = sectionButton;
		  window.dataLayer.push({'event': 'sectionNavClick', 'section': sectionButton});
		}

	setMenuIndex = (menuIndex) => {
		this.setState({displayedMenuIndex:menuIndex}, () => {
			// height of header nav changes per menu
			this.recalcHeaderHeight();
		});
		
	}

	splashScreen = () => {
	 	//After 3 second, set render to true
		 setTimeout(function() { //Start the timer
			this.setState({
				showSplash: false,
				showLiveMenu: true,
			}, () => {
				this.recalcHeaderHeight();
			});
		}.bind(this), 3000)
	}


	render() {
		const { file, numPages } = this.state;
		return (
			<div className="live-menu-container">
				<div className='splash-screen' style={{ display: this.state.showSplash ? "block" : "none" }}>
					<div className='splash-restaurant-logo-container'>
						{this.state.restInfo.brand && this.state.restInfo.brand.logoURI ? 
						<React.Fragment>
						<p>Loading menus for:</p>
						<img width='100%' src={this.state.restInfo.brand.logoURI}/>
						</React.Fragment>
						:
						<h2>{this.state.restInfo.name ? this.state.restInfo.name : 'Loading Menu...'}</h2>
						}
						</div>
					<div className='splash-spinner'>
						<Spinner className="splash-spinner-custom-border" animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner>						
					</div>
					
					<div className='splash-footer'>
						{/* <h2>See The Specials</h2> */}
						<img width='90px' src='https://www.seethespecials.com/wp-content/uploads/2020/08/cropped-png-01-2.png'/>
						<p style={{fontSize: "1.1em", fontWeight: 700, margin:'10px'}}>Powered by:</p>
						<img width='70px' src='https://www.grumbl.es/home/wp-content/uploads/2018/06/grumblesBearText150x150.png'/>
					</div>
				</div>
				<div className='live-menu' style={{ display: this.state.showLiveMenu ? "block" : "none" }}>
					{
						this.state.menusData < 1 ? 
						<h2 style={{margin:'50px', textAlign:'center'}}>Sorry! There is no menu available for {this.state.restInfo.name ? this.state.restInfo.name : 'this restaurant.'}</h2>
						:
						<div className='live-menu-container'>
							<div 
								id='menu_nav_header'
								className='menu-nav-header'
								// ref={ headerElement => { this.headerElement = headerElement } }
								// ref={ this.headerElement }
							>
								<div className='menu-name-description'>
									<h2>{this.state.menusData[this.state.displayedMenuIndex].name}</h2>
									<h5>{this.state.menusData[this.state.displayedMenuIndex].description}</h5>
								</div>
								{this.state.menusData[this.state.displayedMenuIndex].sections.length > 1 ?
								<div className='menu-sections-nav-container'>
									<div className='menu-sections-nav'>
									{this.state.menusData[this.state.displayedMenuIndex].sections.map((section, i) => {
										return([
										<Button as='div' key={'section-'+section.name+i} className='section-button' variant='custom' active={this.state.activeSection === 'section-' + section.name.replace(/\s/g, '-').toLowerCase() ? true : false} onClick={() => this.sectionNavClick('section-' + section.name.replace(/\s/g, '-').toLowerCase())}>
											{section.name}
										</Button>
										])
									})
									}
									</div>
								</div>									
								:null}
							</div>
							<div className='menu-sections-container' style={{marginTop:this.state.headerHeight+20}}>
							{this.state.menusData[this.state.displayedMenuIndex].sections.map((section, i) => {
								return([
								<div key={'section-key-'+i} className={`menu-section section-${section.name.replace(/\s/g, '-').toLowerCase()}`}>
									<Element name={'section-'+section.name.replace(/\s/g, '-').toLowerCase()} id={'section-'+section.name.replace(/\s/g, '-').toLowerCase()} className="element"/>
									<div className='section-header'>
										<div className='section-name'>
											<h2>{section.name}</h2>
										</div>
										<div className='section-description'>
											<h5>{section.description}</h5>
										</div>
									</div>
									<div className='meal-cards'>
									{section.meals.map((meal, i) => {
										return([
											<div key={'mealcard'+i} className={'meal-card ' + 'id-' +  + meal.ID}>
											{/* <div key={'mealcard'+i} className={'meal-card ' + 'id-' +  + meal.ID} onClick={meal.coverImage === '' ? null : () => this.showMealImage(meal.ID)}> */}
												<div className='meal-name-description-price'>
													<div className='meal-name'>
														<h4>{meal.name}</h4>
													</div>
													<div className='meal-description'>
														<h6>{meal.description}</h6>
													</div>
													{meal.textPrice !== "" ?
													<div className='meal-price'>
														<h3>{meal.textPrice}</h3>													
													</div>											
													:
														meal.price <= 0 ? null : 
															<div className='meal-price'>
																<h3>${meal.price}</h3>													
															</div>
													}
												</div>	
												{(meal.ID === 4838150694240256 || meal.ID === 4828340129103872) && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-torta-cubana-torta.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Cubana Torta" ios-src="https://grumbl.es/img/ar/el-burrito-torta-cubana-torta.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>

												: meal.ID === 6667738042859520 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-huarache-al-pastor-huarache.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Huarache" ios-src="https://grumbl.es/img/ar/el-burrito-huarache-al-pastor-huarache.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: (meal.ID === 4790007545987072 || meal.ID === 6238603847925760) && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-specials-cheesesteak-burrito.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Cheesesteak Burrito" ios-src="https://grumbl.es/img/ar/el-burrito-specials-cheesesteak-burrito.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 5133204310720512 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-salads-chicken-salad.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Chicken Taco Salad" ios-src="https://grumbl.es/img/ar/el-burrito-salads-chicken-salad.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 4627044461707264 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-sopas-torilla-soup.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Torilla Soup" ios-src="https://grumbl.es/img/ar/el-burrito-sopas-torilla-soup.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 5090405129388032 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-tacos-three-different.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Tacos" ios-src="https://grumbl.es/img/ar/el-burrito-tacos-three-different.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 6307763760136192 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-tacos-birria-tacos.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Birria Tacos" ios-src="https://grumbl.es/img/ar/el-burrito-tacos-birria-tacos.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 4511326701355008 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-burritos-burrito-de-picadillo.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Burrito De Picadillo" ios-src="https://grumbl.es/img/ar/el-burrito-burritos-burrito-de-picadillo.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>

												: meal.ID === 4734608562192384 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-burritos-burrito-de-pollo.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Burrito De Pollo" ios-src="https://grumbl.es/img/ar/el-burrito-burritos-burrito-de-pollo.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 4734608562192384 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-burritos-burrito-de-pollo.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Burrito De Pollo" ios-src="https://grumbl.es/img/ar/el-burrito-burritos-burrito-de-pollo.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 5312362865229824 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-quesadillas-steak-quesadilla.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Steak Quesadilla" ios-src="https://grumbl.es/img/ar/el-burrito-quesadillas-steak-quesadilla.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 6676314891223040 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-chimichangas-steak-chimichanga.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Steak Chimichanga" ios-src="https://grumbl.es/img/ar/el-burrito-chimichangas-steak-chimichanga.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 4888757958344704 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-tostadas-tinga-tostada.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Tinga Tostada" ios-src="https://grumbl.es/img/ar/el-burrito-tostadas-tinga-tostada.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 5447516157902848 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-tostadas-chicken-tostada.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Chicken Tostada" ios-src="https://grumbl.es/img/ar/el-burrito-tostadas-chicken-tostada.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 5752944368549888 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-tostadas-steak-tostada.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Steak Tostada" ios-src="https://grumbl.es/img/ar/el-burrito-tostadas-steak-tostada.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 5977629240852480 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-tostadas-shrimp-tostada.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Shrimp Tostada" ios-src="https://grumbl.es/img/ar/el-burrito-tostadas-shrimp-tostada.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 4867650006024192 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-platos-hawaiian-burger.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Hawaiian Burger" ios-src="https://grumbl.es/img/ar/el-burrito-platos-hawaiian-burger.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 4983620766793728 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-desserts-fried-ice-cream.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Fried Ice Cream" ios-src="https://grumbl.es/img/ar/el-burrito-desserts-fried-ice-cream.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.ID === 5029495446700032 && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/el-burrito-chimichangas-chicken-chimichanga.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="El Burrito - Chicken Chimichanga" ios-src="https://grumbl.es/img/ar/el-burrito-chimichangas-chicken-chimichanga.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>

												: (meal.ID === 5442588668592128 || meal.ID === 5957128871215104) && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/cakeposer-valentines.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="Be mine - Valentine" ios-src="https://grumbl.es/img/ar/cakeposer-valentines.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>

												: (meal.ID === 6201193340600320 || meal.ID === 4814607910699008) && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/cakeposer-bunny.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="Be mine - Valentine" ios-src="https://grumbl.es/img/ar/cakeposer-bunny.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: (meal.ID === 6230310333186048 || meal.ID === 4866573458210816) && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/cakeposer-lion.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="Be mine - Valentine" ios-src="https://grumbl.es/img/ar/cakeposer-lion.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: (meal.ID === 5992473365053440 || meal.ID === 6537228092178432) && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/chow-bistro-desserts-warm-apple-crumble.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="Be mine - Valentine" ios-src="https://grumbl.es/img/ar/chow-bistro-desserts-warm-apple-crumble.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: (meal.ID === 6327381459468288 || meal.ID === 5377557864120320) && this.state.isWidthMobile ? 
												<model-viewer reveal="manual" poster={meal.coverImage} src="https://grumbl.es/img/ar/chow-bistro-crispy-calamari.glb" ar ar-scale="fixed" camera-controls touch-action="pan-y" alt="Be mine - Valentine" ios-src="https://grumbl.es/img/ar/chow-bistro-crispy-calamari.usdz" xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': meal.name})}></model-viewer>
												
												: meal.coverImage === '' ? null :  
												<div className='meal-img' onClick={() => {this.showMealImage(meal.ID); trackMenuItemClick({'event' : 'photoMenuItemClick', 'menuItem': meal.name});}}>
													<img src={meal.coverImage} alt={meal.name} className='hybrid-list-image'/>
												</div>
												}		
											</div>		
										])
									})
									}
									</div>
								</div>										
								])
							})
							}
							</div>
						</div>
					}
				</div>
				<Modal 
				dialogClassName="modal-image" 
				show={this.state.modelImagesShow} onHide={() => this.handleDismiss()}
				centered
				>
					<Modal.Header closeButton />
					<Modal.Body>
						<Carousel>
							{this.state.modelImages.map((url, i) => {
								return([
									<Carousel.Item>
										<img 
											src={url}
											className='modal-carousel-image'
										/>
									</Carousel.Item>
								])
							})
							}
						</Carousel>
					</Modal.Body>
					<Modal.Footer>
						<MdScreenRotation size='1.75em' />
						<h5>Rotate for BIGGER image</h5>
						<MdScreenRotation  size='1.75em'/>
					</Modal.Footer>
				</Modal>
				<Footer setMenuIndex={this.setMenuIndex} scrollToTop={this.scrollToTop}/>
			</div>
		)
	}
}
