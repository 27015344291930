import React, {useEffect, useContext} from 'react';
import axios from "axios";
import PlaceContext from '../contexts/PlaceContext'
import queryString from 'query-string';

const queryStringParams = queryString.parse(window.location.search);

const GrumblesClient = axios.create({
	baseURL: process.env.REACT_APP_GRUMBLES_API,
	crossDomain:true,
	mode: 'cors',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	}
});

export default {

	getMealsbymenu: async function() {
		if(queryStringParams.restID){
			try{
				const response = await GrumblesClient.get(`places/${queryStringParams.restID}/mealsbymenu`)
				return response;
			} catch (error) {
				throw error;
			}
		}else{
			console.log("restID not found")
			return;
		}
	},

	getPlaceInfo: async function() {
		try{
			const response = await GrumblesClient.get(`places/${queryStringParams.restID}`)
			return response;
		} catch (error) {
			throw error;
		}
	}

}