import React, {useContext, useState, useEffect} from 'react'
import PlaceContext from '../contexts/PlaceContext'
import {animateScroll as scroll, scroller} from 'react-scroll'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/App.css';
import Footer from '../components/Footer';

const Join2 = (props) => {
	const {menuNames, menusData, queryStringParams, restInfo} = useContext(PlaceContext)
	const [pageveiwAnalyticsParams, setPageveiwAnalyticsParams] = useState({
		'event': 'virtualPageview', 
		'pageURL' : window.location.href, 
		'pageTitle' : 'Join', 
		'placeName' : restInfo.name ? restInfo.name : 'unknown place'
	})
	// disable submission button until all fields are filled
	const [submitDisabled, setSubmitDisabled] = useState(true)

	// function to check if all fields are filled
	const checkFields = () => {
		if (document.getElementById('agilefield-1').value !== '' && document.getElementById('agilefield-3').value !== '' && document.getElementById('choose-date-agilefield-4').value !== '' && document.getElementById('choose-month-agilefield-4').value !== '' && document.getElementById('choose-year-agilefield-4').value !== '') {
			console.log('all fields filled')
			setSubmitDisabled(false)
		} else {
			console.log('not all fields filled')
			setSubmitDisabled(true)
		}
	}


	useEffect(() => {
		// adding agilecrm tracking script
		let agileMinScript = document.createElement("script");
		agileMinScript.setAttribute("src", "https://elburritopa.agilecrm.com/stats/min/agile-min.js");
		agileMinScript.setAttribute("type", "text/javascript");
		agileMinScript.async = true;
		document.body.appendChild(agileMinScript);

		let agileTrackingScript = document.createElement("script");
		agileTrackingScript.setAttribute("type", "text/javascript");
		agileTrackingScript.async = true;
		agileTrackingScript.innerHTML = "console.log('agile script inserted'); var Agile_API = Agile_API || {}; Agile_API.on_after_load = function(){ _agile.set_account('5qhf0u0645166ge3n7hc9or3v7', 'elburritopa', false); _agile.track_page_view(); _agile_execute_web_rules();};"
		document.body.appendChild(agileTrackingScript);
		checkFields()
	}, [])

	useEffect(() =>{
		console.log('restInfo:')
		console.log(restInfo)
		setPageveiwAnalyticsParams(pageveiwAnalyticsParams => ({...pageveiwAnalyticsParams, 'placeName' : restInfo.name ? restInfo.name : 'unknown place'}))
	},[restInfo])

	// update the dataLayer with the new pageview data when restInfo changes
	useEffect(() =>{
		// window.dataLayer.push(pageveiwAnalyticsParams)
	},[pageveiwAnalyticsParams])

	const setMenuIndex = (menuIndex) => {
		window.location.assign('qrlivemenu?restID=' + restInfo.ID + '&menuIndex=' + menuIndex) 
	}

	const scrollToTop = () => {
		scroll.scrollToTop();
		props.scrollToTop();
	}

	const setNextBirthday = (event) => {
		window.prepareDateTypeValue(event);
		var month = document.getElementById("choose-month-agilefield-4").value;
		var currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth()+1;
		const tomorrow = new Date().getDate() + 1;
	
		function getMonthFromString(mon){
			return new Date(Date.parse(mon +" 1, 2012")).getMonth()+1
		}
		if(month){
			month = getMonthFromString(month);
			var startNextBdayMonth = "";
			//if bday is the current month set next notification to tomorrow else the first of their birth month
			if(month === currentMonth){
				startNextBdayMonth = month + "-" + tomorrow + "-" + currentYear;
			}else{
				// if birth month is less than current month, add 1 year
				if(month < currentMonth){
					currentYear = currentYear + 1;
				}
				startNextBdayMonth = month + "-01-" + currentYear;
			}
			console.log("nbn: ", startNextBdayMonth)
			document.getElementById("date-hiddeninput-agilefield-5").value = Date.parse(startNextBdayMonth)/1000;
		}
	
	};
	
	return (
		<div className="restaurant-details-container">
			<div className='restaurant-details'>
				{!restInfo?.coverImage?.includes("noPhotosPlaceholder.jpg") ?
				<div className='restaurant-cover-image-container'>
					<img src={restInfo.coverImage} className='restaurant-cover-image'/>
				</div>
				:null}
				<div className='restaurant-header'>
					<img src='https://www.elburritopa.com/wp-content/uploads/2022/10/birthday-el-burrito.png' style={{maxWidth:'100%', height: 'auto'}}/>
					<h1 style={{textAlign:'center'}}>El Burrito Birthday Club!</h1>
				</div>
				<div className='restaurant-body'>
					<p style={{textAlign:'center'}}>We appreciate you and the fact that you were born! Sign up below for El Burrito offers and we'll send you a coupon for free Burrito to enjoy anytime during your birthday month :)</p>
					<p style={{textAlign:'center'}}>¡Te apreciamos y el hecho de que hayas nacido! Regístrese a continuación para recibir ofertas de El Burrito y le enviaremos un cupón para un Burrito gratis para disfrutar en cualquier momento durante el mes de su cumpleaños :)</p>

					<link href="https://doxhze3l6s7v9.cloudfront.net/app/static/agile/forms/agile-form.min.css" rel="stylesheet"/>
					<form class="form-view theme7" id="agile-form" action="https://elburritopa.agilecrm.com/formsubmit" style={{maxWidth:450}} method="GET">
					<fieldset>

					<div style={{display: 'none', height: 0, width: 0}}>
					<input type="hidden" id="_agile_form_name" name="_agile_form_name" value="El Burrito Birthday Club"/>
					<input type="hidden" id="_agile_domain" name="_agile_domain" value="elburritopa"/>
					<input type="hidden" id="_agile_api" name="_agile_api" value="5qhf0u0645166ge3n7hc9or3v7"/>
					<input type="hidden" id="_agile_redirect_url" name="_agile_redirect_url" value="https://www.elburritopa.com/your-birthday/"/>
					<input type="hidden" id="_agile_document_url" name="_agile_document_url" value=""/>
					<input type="hidden" id="_agile_confirmation_msg" name="_agile_confirmation_msg" value="Thanks for signing up for our Birthday Club! Be on the lookout for an email during your birthday month with your free burrito."/>
					<input type="hidden" id="_agile_form_id_tags" name="tags" value="ElBurritoBirthdayClub"/>

					<input type="hidden" id="_agile_form_id" name="_agile_form_id" value="4899905957330944"/>
					</div>
					<div class="agile-group required-control">
					<label class="agile-label" for="agilefield-1">Full Name<span class="agile-span-asterisk"> *</span></label>
					<div class="agile-field-xlarge agile-field">
					<input maxlength="250" id="agilefield-1" name="first_name" type="text" placeholder="Enter your first and last names" class="agile-height-default" required="" onChange={() => checkFields()}/>
					</div>
					<div class="agile-custom-clear"></div>
					</div>
					<div class="agile-group required-control">
					<label class="agile-label" for="agilefield-3">Email<span class="agile-span-asterisk"> *</span></label>
					<div class="agile-field-xlarge agile-field">
					<input maxlength="250" id="agilefield-3" name="email" type="email" placeholder="Enter email" class="agile-height-default" required="" onChange={() => checkFields()}/>
					</div>
					<div class="agile-custom-clear"></div>
					</div>
					<div class="agile-group required-control">
					<label class="agile-label" for="agilefield-4">Birthday<span class="agile-span-asterisk"> *</span></label>
					<div id="agilefield-4" class="agile-field date-field">
						<select id="choose-date-agilefield-4" class="choose-date" onChange={(event)=> {setNextBirthday(event);  checkFields();}}>
							<option value="">Day</option>
							<option value="01">01</option>
							<option value="02">02</option>
							<option value="03">03</option>
							<option value="04">04</option>
							<option value="05">05</option>
							<option value="06">06</option>
							<option value="07">07</option>
							<option value="08">08</option>
							<option value="09">09</option>
							<option value="10">10</option>
							<option value="11">11</option>
							<option value="12">12</option>
							<option value="13">13</option>
							<option value="14">14</option>
							<option value="15">15</option>
							<option value="16">16</option>
							<option value="17">17</option>
							<option value="18">18</option>
							<option value="19">19</option>
							<option value="20">20</option>
							<option value="21">21</option>
							<option value="22">22</option>
							<option value="23">23</option>
							<option value="24">24</option>
							<option value="25">25</option>
							<option value="26">26</option>
							<option value="27">27</option>
							<option value="28">28</option>
							<option value="29">29</option>
							<option value="30">30</option>
							<option value="31">31</option>
						</select>
						<select id="choose-month-agilefield-4" class="choose-month"  onChange={(event)=> {setNextBirthday(event);  checkFields();}}>
							<option value="">Month</option>
							<option value="Jan">Jan</option>
							<option value="Feb">Feb</option>
							<option value="Mar">Mar</option>
							<option value="Apr">Apr</option>
							<option value="May">May</option>
							<option value="June">June</option>
							<option value="July">July</option>
							<option value="Aug">Aug</option>
							<option value="Sep">Sep</option>
							<option value="Oct">Oct</option>
							<option value="Nov">Nov</option>
							<option value="Dec">Dec</option>
							
						</select>
						<select id="choose-year-agilefield-4" class="choose-year"  onChange={(event)=> {setNextBirthday(event);  checkFields();}}>
							<option value="" selected="1">Year</option>
							<option value="2017">2017</option>
							<option value="2016">2016</option>
							<option value="2015">2015</option>
							<option value="2014">2014</option>
							<option value="2013">2013</option>
							<option value="2012">2012</option>
							<option value="2011">2011</option>
							<option value="2010">2010</option>
							<option value="2009">2009</option>
							<option value="2008">2008</option>
							<option value="2007">2007</option>
							<option value="2006">2006</option>
							<option value="2005">2005</option>
							<option value="2004">2004</option>
							<option value="2003">2003</option>
							<option value="2002">2002</option>
							<option value="2001">2001</option>
							<option value="2000">2000</option>
							<option value="1999">1999</option>
							<option value="1998">1998</option>
							<option value="1997">1997</option>
							<option value="1996">1996</option>
							<option value="1995">1995</option>
							<option value="1994">1994</option>
							<option value="1993">1993</option>
							<option value="1992">1992</option>
							<option value="1991">1991</option>
							<option value="1990">1990</option>
							<option value="1989">1989</option>
							<option value="1988">1988</option>
							<option value="1987">1987</option>
							<option value="1986">1986</option>
							<option value="1985">1985</option>
							<option value="1984">1984</option>
							<option value="1983">1983</option>
							<option value="1982">1982</option>
							<option value="1981">1981</option>
							<option value="1980">1980</option>
							<option value="1979">1979</option>
							<option value="1978">1978</option>
							<option value="1977">1977</option>
							<option value="1976">1976</option>
							<option value="1975">1975</option>
							<option value="1974">1974</option>
							<option value="1973">1973</option>
							<option value="1972">1972</option>
							<option value="1971">1971</option>
							<option value="1970">1970</option>
							<option value="1969">1969</option>
							<option value="1968">1968</option>
							<option value="1967">1967</option>
							<option value="1966">1966</option>
							<option value="1965">1965</option>
							<option value="1964">1964</option>
							<option value="1963">1963</option>
							<option value="1962">1962</option>
							<option value="1961">1961</option>
							<option value="1960">1960</option>
							<option value="1959">1959</option>
							<option value="1958">1958</option>
							<option value="1957">1957</option>
							<option value="1956">1956</option>
							<option value="1955">1955</option>
							<option value="1954">1954</option>
							<option value="1953">1953</option>
							<option value="1952">1952</option>
							<option value="1951">1951</option>
							<option value="1950">1950</option>
							<option value="1949">1949</option>
							<option value="1948">1948</option>
							<option value="1947">1947</option>
							<option value="1946">1946</option>
							<option value="1945">1945</option>
							<option value="1944">1944</option>
							<option value="1943">1943</option>
							<option value="1942">1942</option>
							<option value="1941">1941</option>
							<option value="1940">1940</option>
							<option value="1939">1939</option>
							<option value="1938">1938</option>
							<option value="1937">1937</option>
							<option value="1936">1936</option>
							<option value="1935">1935</option>
							<option value="1934">1934</option>
							<option value="1933">1933</option>
							<option value="1932">1932</option>
							<option value="1931">1931</option>
							<option value="1930">1930</option>
							<option value="1929">1929</option>
							<option value="1928">1928</option>
							<option value="1927">1927</option>
							<option value="1926">1926</option>
							<option value="1925">1925</option>
							<option value="1924">1924</option>
							<option value="1923">1923</option>
							<option value="1922">1922</option>
							<option value="1921">1921</option>
							<option value="1920">1920</option>
							<option value="1919">1919</option>
							<option value="1918">1918</option>
							<option value="1917">1917</option>
							<option value="1916">1916</option>
							<option value="1915">1915</option>
							<option value="1914">1914</option>
							<option value="1913">1913</option>
							<option value="1912">1912</option>
							<option value="1911">1911</option>
							<option value="1910">1910</option>
							<option value="1909">1909</option>
							<option value="1908">1908</option>
							<option value="1907">1907</option>
							<option value="1906">1906</option>
							<option value="1905">1905</option>
						</select>
					</div>
					<div><input id="date-hiddeninput-agilefield-4" class="date-hiddeninput" data-agilefield-type="DATE" type="" name="Birthday" value="" required=""/></div>
					<div class="agile-custom-clear"></div>
					</div>

					<div class="date-hiddeninput">
					<div><input id="date-hiddeninput-agilefield-5" class="date-hiddeninput" data-agilefield-type="DATE" type="" name="Next Birthday Notification Date" value=""/></div>
					<div class="agile-custom-clear"></div>
					</div>

					<div class="agile-group">
					<label class="agile-label">&nbsp;</label>
					<div class="agile-field agile-button-field">
						<button type="submit" class="agile-button" className={ submitDisabled ? "" : "successful-submission"} style={submitDisabled ? {backgroundColor:'grey', opacity:'25%'} : {}} disabled={submitDisabled}>Submit</button>
						<br/><span id="agile-error-msg"></span>
					</div>
					</div>

					</fieldset>
					</form>
				</div>
				<div className='powered-by-footer'>
					<div className='powered-by-footer-live-menus'>
						<a target="_blank" href='https://www.seethespecials.com	'>See The Specials</a>&nbsp;
					</div>
					<div className='powered-by-footer-grumbles'>
						is powered by <a target="_blank" href="https://www.grumbl.es">Grumbles</a>
					</div>
				</div>
			</div>
			<Footer setMenuIndex={() => setMenuIndex()} scrollToTop={() => scrollToTop()} />
		</div>
	)
}

export default Join2;