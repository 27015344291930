import React, { Component, useState } from 'react';
import queryString from 'query-string';
import {DropdownButton, Dropdown, Row, Col, Form, Modal, Toast, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
// import REACT_APP_GRUMBLES_API from '../../.env';

export default class QrMenu extends Component {
	
	constructor(props) {
		super(props);
	 
		this.state = {
		  restID: '',
		  menuImages: [],
		  allActiveMenuInfo: [],
		  menuInfoToDisplay: [],
		  restInfo: {},
		  specialsMenus: [],
		  isPhoto: false,
		  isURL: true,
		  hasTitles: false,
		  uniqueMenuTitles: [],
		  numPages: 1,
		};
		// this.numPages = this.numPages.bind(this);
	  }
	 
	onDocumentLoadSuccess = ({ numPages }) => {
		this.setState({ numPages });
	}

	componentDidMount(){
		const queryStringParams = queryString.parse(this.props.location.search);
		this.setState({
			restID: queryStringParams.restID
		});
		console.log(queryStringParams.restID);

		// fetch('https://grumbles-api-qc.appspot.com/places/' + queryStringParams.restID, 
		fetch(process.env.REACT_APP_GRUMBLES_API + 'places/' + queryStringParams.restID, 
		// fetch('https://grumbles-api-dev-200216.appspot.com/places/' + queryStringParams.restID, 
		{
			crossDomain:true,
			mode: 'cors',
            method: 'GET',
            headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
		},
		}
		)
		// // fetch('https://grumbles-api.appspot.com/places/6705694917001216', {mode: 'cors'})
		// .then(response => response.text())
		// .then(text => console.log(text))
		.then(response => response.json())
		.then(data => {
			console.log('data:');
			console.log(data);
			console.log('place menu:');
			console.log(data.data.place.menu);
			this.setState({restInfo:data.data.place});
			let menuInfoAry = this.state.allActiveMenuInfo;
			let specialsAry = [];
			let nonSpecialsAry = [];
			let uniqueMenuTitles = [];
			data.data.place.menu.map((menu,i) => {
				if(!menu.menuMetadata.status || menu.menuMetadata.status === null || menu.menuMetadata.status === "" || menu.menuMetadata.status === "active" ){
					if(menu.menuMetadata.type === 'url'){
						// if any of the menus are of type url, take the user to that url
						window.location.replace(menu.uri);
					}else if (menu.menuMetadata.type === 'livemenu'){
						// if any of the menus are of type livemenu, take the user to the livemenu page
						window.location.replace('/qrlivemenu?restID=' + queryStringParams.restID);
					}else if (menu.menuMetadata.type === 'photo' || menu.menuMetadata.type === 'pdf'){
					console.log('menuInfoAry Before:');
						console.log(menuInfoAry);
						if(menu.menuMetadata.title){
							this.setState({
								hasTitles:true
							});	
						}
						if(menu.menuMetadata.kind === 'special'){
							specialsAry.push(menu);
						}else{
							nonSpecialsAry.push(menu);
						}
						menuInfoAry.push(menu);

						console.log('menuInfoAry:');
						console.log(menuInfoAry);
						// let photoAry = this.state.menuImages;
						// photoAry.push(menu.uri);
						this.setState({
							// menuImages:photoAry,
							isPhoto:true,
							isURL:false
						});	
					}
				}
			})
			uniqueMenuTitles = [...new Set(nonSpecialsAry.map(item => item.menuMetadata.title))];
			console.log("uniqueMenuTitles:");
			console.log(uniqueMenuTitles);
			this.setState({
				allActiveMenuInfo:menuInfoAry,
				menuInfoToDisplay:menuInfoAry,
				specialsMenus:specialsAry,
				uniqueMenuTitles:uniqueMenuTitles
			}, () => {
				console.log('this.state.uniqueMenuTitles.length');
				console.log(this.state.uniqueMenuTitles.length);
			});
	
		})
		.catch(function(error) {  
			console.log('Request failed. Error: ', error);
		});
	}

	toggleMenus (title){
		console.log('triggered');
		let chosenMenu = [];
		if(title === "allmenus"){
			this.setState({
				menuInfoToDisplay:this.state.allActiveMenuInfo
			});		
		}else{
			this.state.allActiveMenuInfo.map((menuInfo, i) => {
				if(menuInfo.menuMetadata.title === title){
					chosenMenu.push(menuInfo);
				}
			})
			console.log('chosenMenu: ');
			console.log(chosenMenu);
			this.setState({
				menuInfoToDisplay:chosenMenu
			});		
		}
	}

	render() {
		const { file, numPages } = this.state;
		return (
			<div style={{textAlign:'center',backgroundColor:'#DC493A'}}>
				<h1 style={{color:'#fff'}}>{this.state.restInfo.name}</h1>
				<h4 style={{color:'#fff'}}>(pinch & zoom)</h4>
				{this.state.hasTitles && this.state.uniqueMenuTitles.length > 1 || this.state.hasTitles && this.state.specialsMenus.length > 1 ?
				<DropdownButton
					size="lg"
					title={'choose a menu'}
					style={{margin:40}}
					>
				{
					this.state.uniqueMenuTitles ?
						this.state.uniqueMenuTitles.map((menuTitle, i) => {
							return([
								<Dropdown.Item onSelect={()=>{this.toggleMenus(menuTitle)}} eventKey={i}>{menuTitle}</Dropdown.Item>
							])
						})
					:null
				}
				{this.state.specialsMenus.length ? 
					<React.Fragment>
						<Dropdown.Divider />
						<Dropdown.Header>Specials</Dropdown.Header>
						{
							this.state.specialsMenus.map((menuInfo, i) => {
								return([
									<Dropdown.Item onSelect={()=>{this.toggleMenus(menuInfo.menuMetadata.title)}} eventKey={i}>{menuInfo.menuMetadata.title}</Dropdown.Item>
								])
							})
						}
					</React.Fragment>
				:null}	
					<Dropdown.Divider />
					<Dropdown.Item onSelect={()=>{this.toggleMenus('allmenus')}} eventKey={100}>All Menus</Dropdown.Item>
				</DropdownButton>
				:null}
				{
					this.state.menuInfoToDisplay.map((menuInfo, i) => {
						return([
							<div>
								{/* <img src={url} alt={this.state.restInfo.name + ' Menu image ' + i} style={menuImage}/> */}
								<h2 style={{color:'#fff'}}>{menuInfo.menuMetadata.title}</h2>
								<h4 style={{color:'#fff'}}>{menuInfo.menuMetadata.description}</h4>
							{menuInfo.menuMetadata.type === 'pdf' ?
								<Document
									file={menuInfo.uri}
									onLoadSuccess={this.onDocumentLoadSuccess}
								>
								{
									Array.from(
									new Array(this.state.numPages),
									(el, index) => (
										<Page
										key={`page_${index + 1}`}
										pageNumber={index + 1}
										width={1000}

										/>
									),
									)
								}

								</Document>
							: 
							<span>
								<img src={menuInfo.uri} alt={this.state.restInfo.name + ' Menu image ' + i} style={menuImage}/>
							</span>
							}
							</div>
						])
					})
				}
				{
					this.state.isURL ? 
					<h3 style={{color:'#fff'}}>
						<Spinner animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner>
					</h3> :
					!this.state.isPhoto ? <h3 style={{color:'#fff'}}>There are no menus available from {this.state.restInfo.name} at this time. Please try again later.</h3> : 
					null
				}
				<div style={{color:'#fff', textAlign:'center', alignItems:'center', justifyContent:'center', padding:'20px'}}>
					<a style={{color:'#fff'}} href='http://www.grumbl.es/'>Powered by <img style={{height:'75px'}} src='https://www.grumbl.es/home/wp-content/uploads/2018/06/grumblesBearText150x150.png'/></a>
				</div>
			</div>
		)
	}
}

const menuImage = {
	width: '100%',
	height: 'auto',
}
