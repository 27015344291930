import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from "react-gtm-module";
import './assets/App.css';
import QrMenu from './pages/QrMenu';
import Dl from './pages/dl';
import Ar from './pages/ar';
import QrLiveMenu from './pages/QrLiveMenu';
import QrLiveMenuRestaurant from './pages/QrLiveMenuRestaurant';
import Join from './pages/Join';
import { PlaceProvider } from './contexts/PlaceContext'

window.dataLayer = window.dataLayer || [];

const env = process.env.NODE_ENV;

const tagManagerArgs = {
  gtmId: 'GTM-WF724NQ'
  // ,
  // auth: env === "production"
  // ? "<live ga_auth>"
  // : "<dev ga_auth>",
  // preview: env === "production"
  // ? "<live ga_auth>"
  // : "<dev ga_auth>"
}

function App() {
  // GA3 legacy and will be sunset March 2023
  ReactGA.initialize('UA-114445265-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  // GA4
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    }, []);
  // componentDidMount(){
  //   ReactGA.initialize('UA-114445265-1');
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }
  return (
    <div className="page-container">
      <div className="content-wrap">
        <PlaceProvider>
          <Router basename="/react">
            <Route exact path="/" render={props => (
            <div className="App">
              {window.location.replace('https://www.grumbl.es/home/')}
              <img src='https://www.grumbl.es/home/wp-content/uploads/2018/06/grumblesBearText150x150.png'/>
            </div>
            )}/>
            <Route path="/ar" component={Ar}/>
            <Route path="/qrmenu" component={QrMenu}/>
            <Route path="/qrlivemenu" component={QrLiveMenu}/>
            <Route path="/qrlivemenurestaurant" component={QrLiveMenuRestaurant}/>
            <Route path="/join" component={Join}/>
            <Route path="/dl" component={Dl}/>
          </Router>
        </PlaceProvider>
      </div>
    </div>
  );
}

const menuImage = {
	width: '100%',
	height: 'auto',
}

export default App;
