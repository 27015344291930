import React, { Component } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { trackMenuItemClick } from '../utils/tracking';


export default class ar extends Component {
	constructor(props) {
		super(props);
	 
		this.state = {
			itemName: '',
			showQR: false,
		};
		// this.sectionDivs = useRef([]);
		this.modelRef = React.createRef();
	  }
	componentDidMount(){

		// get the url parameter
		const urlParams = new URLSearchParams(window.location.search);
		const item = urlParams.get('item');
		if(item){
			this.setState({itemName: item});
			console.log("item in url:", item);
		}else{
			console.log("no item in url. defaulting to 'el-burrito-specials-cheesesteak-burrito'");
			this.setState({itemName: 'el-burrito-specials-cheesesteak-burrito'});
		}		

		// if desktop the set showQR to true
		if(window.innerWidth > 500){
			this.setState({showQR: true});
		}
	}

	// use model-viewer to serve 3d models based on the url parameter and user's phone, iphone or android
	render() {
		return (
			<div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', textAlign:'center', backgroundColor:'#DC493A', height:'100%', padding:"50px"}}>
				<h1>Grumbles AR</h1>
				<p style={{color:'#fff'}}>press the ar box icon (<img width="25px" src="https://grumbl.es/img/ar/ar-icon.png"/>) to see this in 3D on the table in front of you!</p>
				<div>
					<model-viewer reveal="manual" loading="eager" poster={`https://grumbl.es/img/ar/` + this.state.itemName + `.png`} src={`https://grumbl.es/img/ar/` + this.state.itemName + `.glb`} ar ar-scale="fixed" camera-controls touch-action="pan-y" auto-rotate shadow-intensity="1" alt={this.state.itemName} ios-src={`https://grumbl.es/img/ar/` + this.state.itemName + `.usdz`} xr-environment ar-modes="scene-viewer quick-look" onClick={()=> trackMenuItemClick({'event' : 'arMenuItemClick', 'menuItem': this.state.itemName})}></model-viewer>
				</div>
				{
				this.state.showQR &&
				<div>
					<h3 style={{color:'#fff'}}>Viewing this on your computer?</h3>
					<p style={{color:'#fff'}}> See it live on your phone for best experience:</p>
					<div>
						<img width="200px" src="https://grumbl.es/img/ar/happy-bakery-cin-bun-ar-qr.png"/>
					</div>
				</div>
				}
			</div>
		)
	}
}
